import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import {
  APP_STARTUP,
  DatacomponentService,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_DEFAULT_ICON,
  ISHTAR365_EMAIL_ICON,
  ISHTAR365_EXCEL_ICON,
  ISHTAR365_FUNCTIONS_KEY,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_MOVIE_ICON,
  ISHTAR365_PDF_ICON,
  ISHTAR365_PICTURE_ICON,
  ISHTAR365_POWERPOINT_ICON,
  ISHTAR365_URL,
  ISHTAR365_WORD_ICON,
  LIBRARY_SERVICE_TOKEN,
  LIST_SERVICE_TOKEN,
  METADATA_SERVICE_TOKEN,
  microsoftAuthenticationInterceptor,
  MsalModule,
  PROJECTS_SERVICE_TOKEN,
  RelationRetrieverService,
  SessionExpiredModule,
  TASKS_SERVICE_TOKEN,
} from 'processdelight-angular-components';
import { AppComponent, appRoutes } from './app/app.component';
import { IshtarCRMService } from './app/services/ishtarcrm.service';
import { LibraryService } from './app/services/library.service';
import { MetadataService } from './app/services/metadata.service';
import { ProjectsService } from './app/services/projects.service';
import { StartUpService } from './app/services/startup.service';
import { TasksService } from './app/services/tasks.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: METADATA_SERVICE_TOKEN,
      useClass: MetadataService,
    },
    {
      provide: LIBRARY_SERVICE_TOKEN,
      useClass: LibraryService,
    },
    {
      provide: PROJECTS_SERVICE_TOKEN,
      useClass: ProjectsService,
    },
    {
      provide: TASKS_SERVICE_TOKEN,
      useClass: TasksService,
    },
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_KEY,
      useValue: environment.ishtarFunctionsKey,
    },
    { provide: ISHTAR365_WORD_ICON, useValue: environment.ishtar_word_icon },
    {
      provide: ISHTAR365_POWERPOINT_ICON,
      useValue: environment.ishtar_powerpoint_icon,
    },
    { provide: ISHTAR365_EXCEL_ICON, useValue: environment.ishtar_excel_icon },
    { provide: ISHTAR365_PDF_ICON, useValue: environment.ishtar_pdf_icon },
    {
      provide: ISHTAR365_PICTURE_ICON,
      useValue: environment.ishtar_picture_icon,
    },
    { provide: ISHTAR365_MOVIE_ICON, useValue: environment.ishtar_movie_icon },
    { provide: ISHTAR365_EMAIL_ICON, useValue: environment.ishtar_email_icon },
    {
      provide: ISHTAR365_DEFAULT_ICON,
      useValue: environment.ishtar_default_icon,
    },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: ISHTAR365_LAST_ROUTE_KEY,
      useValue: `${AppComponent.domainName}-LastRouteKey`,
    },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['clients'] },
    {
      provide: LIST_SERVICE_TOKEN,
      useClass: IshtarCRMService,
    },
    {
      provide: RelationRetrieverService,
      useClass: IshtarCRMService,
    },
    {
      provide: DatacomponentService,
      useClass: IshtarCRMService,
    },
    provideRouter(appRoutes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule,
      SessionExpiredModule,
      MatSnackBarModule,
      HttpClientModule
    ),
    provideAnimations(),
  ],
});
