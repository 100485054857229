import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import {
  DashboardComponent,
  Filter,
  IProjectsService,
  Project,
} from 'processdelight-angular-components';
import { map, Observable } from 'rxjs';

@Injectable()
export class ProjectsService implements IProjectsService {
  private readonly apiBase = `${location.origin}/web`;
  private readonly httpClient = inject(HttpClient);

  public getProjects(
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number
  ): Observable<{ result: Project[]; totalRecordCount: number }> {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/project?orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;
    if (filter !== '') url += `&filter=${filter}`;
    return this.httpClient
      .get<{ Result: Project[]; TotalRecordCount: number }>(url)
      .pipe(
        map((x) => ({ result: x.Result, totalRecordCount: x.TotalRecordCount }))
      );
  }

  public getProject(projectId: string): Observable<Project> {
    return this.httpClient
      .get<Project>(`${this.apiBase}/project/${projectId}`)
      .pipe(map((p) => new Project(camelcaseKeys(p))));
  }
}
